import React from "react";

function PageFooter() {
  return (
    <footer>
      <p className="text-center mb-0">
        Powered by <strong>Liquid</strong>
      </p>
    </footer>
  );
}

export default PageFooter;
