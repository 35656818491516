import React, { useState } from "react";
import classNames from "classnames";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Account } from "../utilities/api";

interface AccountCardProps {
  account: Account;
  isSelected: boolean;
  onClick: (account: Account) => void;
}

interface AccountsListProps {
  accounts: Account[];
  onAccountSelect: (account: Account) => void;
}

function AccountCard({ account, isSelected, onClick }: AccountCardProps) {
  const [isHovered, setHovered] = useState(false);
  const toggleHover = () => setHovered(!isHovered);
  return (
    <div
      className={classNames(
        "card",
        { "border-dark": isSelected },
        { shadow: isHovered },
        { "shadow-sm": !isHovered }
      )}
      onClick={(e) => {
        e.preventDefault();
        onClick(account);
      }}
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
      role="button"
      style={{ cursor: "pointer" }}
    >
      <div className="card-body">
        <h5 className="card-title">{account.name}</h5>
        <p className="card-text">#{account.id}</p>
      </div>
      {isSelected && (
        <div
          className="text-dark position-absolute"
          style={{
            bottom: "1em",
            right: "1em",
          }}
        >
          <FontAwesomeIcon icon={faCheck} />
        </div>
      )}
    </div>
  );
}

function AccountsList({ accounts, onAccountSelect }: AccountsListProps) {
  const [selected, setSelected] = useState<Account>();
  return (
    <ul className="list-unstyled mb-0">
      {accounts.map((account) => (
        <li
          className="mb-3"
          data-account={account.id}
          key={`account-${account.id}`}
        >
          <AccountCard
            account={account}
            isSelected={selected?.id === account.id}
            onClick={() => {
              setSelected(account);
              onAccountSelect(account);
            }}
          />
        </li>
      ))}
    </ul>
  );
}

export default AccountsList;
