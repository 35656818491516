import React from "react";

function LoadingIndicator(props: {}) {
  return (
    <div className="d-flex flex-column align-items-center" {...props}>
      <p>Loading&hellip;</p>
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading&hellip;</span>
      </div>
    </div>
  );
}

export default LoadingIndicator;
