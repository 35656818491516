import React, { FormEvent, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import classNames from "classnames";
import {
  faArrowLeft,
  faChevronRight,
  faCircleNotch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageFooter from "../components/PageFooter";
import PageHeader from "../components/PageHeader";
import api from "../utilities/api";
import { services as servicesNames } from "../utilities/fixtures";

interface LoginPageErrors {
  username?: string;
  password?: string;
}

interface LoginPageParams {
  service: string;
}

function LoginPage() {
  const history = useHistory();
  const params: LoginPageParams = useParams();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState<LoginPageErrors>();
  const [submitting, setSubmitting] = useState(false);
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setErrors(undefined);
    setSubmitting(true);
    try {
      const {
        data: { session },
      } = await api.createNewSession({
        service: params.service,
        username,
        password,
      });
      window.top?.postMessage(
        JSON.stringify({
          type: "liquid.login",
          service: params.service,
          session,
        }),
        "*"
      );
      history.push(`/services/${params.service}/sessions/${session}/accounts`);
    } catch (e) {
      setErrors({ username: "Please check your credentials." });
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <>
      <div className="mb-3">
        <PageHeader
          leftIcon={faArrowLeft}
          onLeftAction={() => history.push("/services")}
          rightIcon={faTimes}
          onRightAction={() => {
            window.top?.postMessage(
              JSON.stringify({ type: "liquid.close" }),
              "*"
            );
          }}
          titleText="Login"
        />
      </div>
      <p className="mb-3">
        Please enter your <strong>{servicesNames[params.service]}</strong>{" "}
        credentials below to authenticate.
      </p>
      <form className="mb-3" onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="login-username" className="form-label">
            <span className="text-danger me-1">*</span> Email address
          </label>
          <input
            className={classNames("form-control", "form-control-lg", {
              "is-invalid": !!errors?.username,
            })}
            defaultValue={username}
            disabled={submitting}
            id="login-username"
            onChange={(e) => setUsername(e.target.value)}
            placeholder="name@example.com"
            required
          />
          {errors?.username && (
            <div className="invalid-feedback">{errors.username}</div>
          )}
        </div>
        <div className="mb-3">
          <label htmlFor="login-password" className="form-label">
            <span className="text-danger me-1">*</span> Password
          </label>
          <input
            className={classNames("form-control", "form-control-lg", {
              "is-invalid": !!errors?.password,
            })}
            defaultValue={password}
            disabled={submitting}
            id="login-password"
            onChange={(e) => setPassword(e.target.value)}
            placeholder="******"
            required
            type="password"
          />
          {errors?.password && (
            <div className="invalid-feedback">{errors.password}</div>
          )}
        </div>
        <div className="btn-toolbar justify-content-center">
          <button className="btn btn-dark btn-lg" disabled={submitting}>
            {submitting ? "Logging in…" : "Continue"}{" "}
            <FontAwesomeIcon
              className="ms-1"
              icon={submitting ? faCircleNotch : faChevronRight}
              spin={submitting}
            />
          </button>
        </div>
      </form>
      <PageFooter />
    </>
  );
}

export default LoginPage;
