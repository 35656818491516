import React, { useEffect, useState } from "react";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingError from "../components/LoadingError";
import LoadingIndicator from "../components/LoadingIndicator";
import PageFooter from "../components/PageFooter";
import PageHeader from "../components/PageHeader";
import ServicesList from "../components/ServicesList";
import api from "../utilities/api";
import useStatefulPromise from "../utilities/hooks/useStatefulPromise";

function ServicesPage() {
  const [searchTerm, setSearchTerm] = useState("");
  const [servicesList, setServicesList] = useState<string[]>([]);
  const {
    error,
    pending: loading,
    reload,
    result,
  } = useStatefulPromise(async () => api.fetchServicesList());
  useEffect(() => {
    if (searchTerm) {
      const filtered = (result?.data || []).filter((x: string) => {
        return x.toLowerCase().indexOf(searchTerm.trim()) >= 0;
      });
      setServicesList(filtered);
    } else {
      setServicesList(result?.data || []);
    }
  }, [searchTerm, result]);
  return (
    <>
      <div className="mb-3">
        <PageHeader
          rightIcon={faTimes}
          onRightAction={() => {
            window.top?.postMessage(
              JSON.stringify({ type: "liquid.close" }),
              "*"
            );
          }}
          titleText="Select a service"
        />
      </div>
      <div className="position-relative mb-3">
        <div
          className="position-absolute"
          style={{
            left: "1em",
            top: "50%",
            transform: "translateY(-50%)",
          }}
        >
          <FontAwesomeIcon icon={faSearch} />
        </div>
        <input
          className="form-control form-control-lg rounded-pill"
          defaultValue={searchTerm}
          disabled={loading}
          onChange={(e) => setSearchTerm(e.target.value?.trim())}
          placeholder="Enter name to search"
          style={{ textIndent: "2em" }}
          type="search"
        />
      </div>
      <div className="mb-3">
        {loading ? (
          <LoadingIndicator />
        ) : error ? (
          <LoadingError className="text-center" reload={reload} />
        ) : !servicesList.length ? (
          <p className="text-center mb-0">No services found to display.</p>
        ) : (
          <ServicesList services={servicesList} />
        )}
      </div>
      <PageFooter />
    </>
  );
}

export default ServicesPage;
