import React from "react";
import { useHistory } from "react-router-dom";
import { logos as serviceLogos } from "../logos";
import { services as servicesNames } from "../utilities/fixtures";

interface ServicesListProps {
  services: string[];
}

function ServicesList({ services }: ServicesListProps) {
  const history = useHistory();
  return (
    <ul className="list-group mb-0" style={{ borderRadius: "1em" }}>
      {services.map((service: string) => {
        return (
          <li
            className="list-group-item list-group-item-action text-center p-3"
            data-service={service}
            key={`service-${service}`}
            onClick={() => {
              history.push(`/services/${service}/sessions`);
            }}
            role="button"
            style={{ cursor: "pointer" }}
          >
            <img
              alt={servicesNames[service]}
              height={32}
              src={serviceLogos[service]}
              style={{ width: "auto" }}
            />
          </li>
        );
      })}
    </ul>
  );
}

export default ServicesList;
