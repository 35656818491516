import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  faArrowLeft,
  faChevronRight,
  faCircleNotch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import store from "store2";
import LoadingError from "../components/LoadingError";
import LoadingIndicator from "../components/LoadingIndicator";
import PageFooter from "../components/PageFooter";
import PageHeader from "../components/PageHeader";
import AccountsList from "../components/AccountsList";
import api, { Account } from "../utilities/api";
import useStatefulPromise from "../utilities/hooks/useStatefulPromise";
import { services as servicesNames } from "../utilities/fixtures";

interface AccountsPageParams {
  service: string;
  session: string;
}

function AccountsPage() {
  const history = useHistory();
  const params: AccountsPageParams = useParams();
  const [error, setError] = useState<string>();
  const [selected, setSelected] = useState<Account>();
  const [submitting, setSubmitting] = useState(false);
  const {
    error: error2,
    pending: loading,
    reload,
    result,
  } = useStatefulPromise(async () =>
    api.fetchAccountsList({
      service: params.service,
      session: params.session,
    })
  );
  const handleSubmit = async () => {
    setError(undefined);
    setSubmitting(true);
    try {
      const account = selected?.id || -1;
      const external_id = store.get("external_id");
      const {
        data: { connection },
      } = await api.createNewConnection({
        service: params.service,
        session: params.session,
        account,
        external_id,
      });
      window.top?.postMessage(
        JSON.stringify({
          type: "liquid.connection",
          service: params.service,
          session: params.session,
          account,
          connection,
        }),
        "*"
      );
      history.push(
        `/services/${params.service}/sessions/${params.session}/accounts/${selected?.id}?connection_id=${connection}`
      );
    } catch (e) {
      setError("Could not connect with this account. Please try again!");
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <>
      <div className="mb-3">
        <PageHeader
          leftIcon={faArrowLeft}
          onLeftAction={() =>
            history.push(`/services/${params.service}/sessions`)
          }
          rightIcon={faTimes}
          onRightAction={() => {
            window.top?.postMessage(
              JSON.stringify({ type: "liquid.close" }),
              "*"
            );
          }}
          titleText="Choose an account"
        />
      </div>
      <p className="mb-3">
        Please choose one of the merchant profile(s) linked with your{" "}
        <strong>{servicesNames[params.service]}</strong> account.
      </p>
      {error && (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      )}
      <div className="mb-3">
        {loading ? (
          <LoadingIndicator />
        ) : error2 ? (
          <LoadingError className="text-center" reload={reload} />
        ) : !result?.data?.length ? (
          <p className="text-center mb-0">No accounts found to display.</p>
        ) : (
          <>
            <div className="mb-3">
              <AccountsList
                accounts={result.data}
                onAccountSelect={(account) => {
                  setSelected(account);
                }}
              />
            </div>
            <div className="btn-toolbar justify-content-center">
              <button
                className="btn btn-dark btn-lg"
                disabled={loading || submitting || !selected}
                onClick={handleSubmit}
              >
                {submitting ? "Connecting…" : "Continue"}{" "}
                <FontAwesomeIcon
                  className="ms-1"
                  icon={submitting ? faCircleNotch : faChevronRight}
                  spin={submitting}
                />
              </button>
            </div>
          </>
        )}
      </div>
      <PageFooter />
    </>
  );
}

export default AccountsPage;
