import { useEffect, useState } from "react";

export default function useStatefulPromise(
  callback: () => Promise<any>,
  autoload = true
) {
  const [error, setError] = useState<any>();
  const [pending, setPending] = useState(true);
  const [preload] = useState<boolean>(autoload);
  const [result, setResult] = useState<any>();
  function reload() {
    setPending(true);
    setError(undefined);
    callback()
      .then((x) => {
        setResult(x);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setPending(false);
      });
  }
  useEffect(() => {
    if (preload) {
      reload();
    }
    // eslint-disable-next-line
  }, []);
  return { error, pending, reload, result };
}
