import React from "react";

export interface LoadingErrorProps {
  className?: string;
  reload?: () => void;
}

function LoadingError({ reload, ...props }: LoadingErrorProps) {
  return (
    <div {...props}>
      <span>Could not retrieve data from server.</span>{" "}
      {reload && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          href=""
          onClick={(e) => {
            e.preventDefault();
            reload();
          }}
        >
          Retry?
        </a>
      )}
    </div>
  );
}

export default LoadingError;
