import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import store from "store2";
import SuccessPage from "./pages/SuccessPage";
import LoginPage from "./pages/LoginPage";
import AccountsPage from "./pages/AccountsPage";
import ServicesPage from "./pages/ServicesPage";

function App() {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const external_id = params.get("external_id");
    if (external_id) {
      store.set("external_id", external_id);
    }
  }, []);
  return (
    <div>
      <Switch>
        <Route exact path="/">
          <Redirect to={{ ...window.location, pathname: "/services" }} />
        </Route>
        <Route path="/services/:service/sessions/:session/accounts/:connection">
          <SuccessPage />
        </Route>
        <Route path="/services/:service/sessions/:session/accounts">
          <AccountsPage />
        </Route>
        <Route path="/services/:service/sessions">
          <LoginPage />
        </Route>
        <Route path="/services">
          <ServicesPage />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
