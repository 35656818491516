import React from "react";
import { useParams } from "react-router-dom";
import { faCheckCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageHeader from "../components/PageHeader";
import { services as servicesNames } from "../utilities/fixtures";
import PageFooter from "../components/PageFooter";

interface SuccessPageParams {
  service: string;
  session: string;
  account: string;
}

function SuccessPage() {
  const params: SuccessPageParams = useParams();
  return (
    <>
      <div className="mb-3">
        <PageHeader
          rightIcon={faTimes}
          onRightAction={() => {
            window.top?.postMessage(
              JSON.stringify({ type: "liquid.close" }),
              "*"
            );
          }}
          titleText="Connected!"
        />
      </div>
      <div className="d-flex justify-content-center mb-3">
        <FontAwesomeIcon
          className="text-success"
          icon={faCheckCircle}
          size="5x"
        />
      </div>
      <p className="mb-3">
        Thank you for successfully connecting your{" "}
        <strong>{servicesNames[params.service]}</strong> account with us.
      </p>
      <p className="mb-3">You can now close this popup.</p>
      <PageFooter />
    </>
  );
}

export default SuccessPage;
