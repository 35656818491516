import React from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface PageHeaderProps {
  titleText: string;
  leftIcon?: IconProp | undefined;
  onLeftAction?: (event: React.MouseEvent<HTMLElement>) => void;
  rightIcon?: IconProp | undefined;
  onRightAction?: (event: React.MouseEvent<HTMLElement>) => void;
}

function PageHeader({
  titleText,
  leftIcon,
  onLeftAction,
  rightIcon,
  onRightAction,
}: PageHeaderProps) {
  return (
    <header className="d-flex align-items-center">
      <div onClick={onLeftAction} role="button">
        {leftIcon && <FontAwesomeIcon icon={leftIcon} />}
      </div>
      <h1 className="h3 text-center mx-auto mb-0">{titleText}</h1>
      <div onClick={onRightAction} role="button">
        {rightIcon && <FontAwesomeIcon icon={rightIcon} />}
      </div>
    </header>
  );
}

export default PageHeader;
