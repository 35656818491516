import {
  AccountsListRequest,
  AccountsListResponse,
  CreateConnectionRequest,
  CreateConnectionResponse,
  CreateSessionRequest,
  CreateSessionResponse,
  ServicesListResponse,
} from "./common";
import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

export async function createNewConnection(
  data: CreateConnectionRequest
): Promise<CreateConnectionResponse> {
  const payload = { external_id: data.external_id };
  return api
    .post(
      `/${data.service}/sessions/${data.session}/accounts/${data.account}/connect`,
      payload
    )
    .then((response) => response.data as CreateConnectionResponse);
}

export async function createNewSession(
  data: CreateSessionRequest
): Promise<CreateSessionResponse> {
  const credentials = {
    username: data.username,
    password: data.password,
  };
  return api
    .post(`/${data.service}/sessions`, credentials)
    .then((response) => response.data as CreateSessionResponse);
}

export async function fetchAccountsList(
  data: AccountsListRequest
): Promise<AccountsListResponse> {
  return api
    .get(`/${data.service}/sessions/${data.session}/accounts`)
    .then((response) => response.data as AccountsListResponse);
}

export async function fetchServicesList(): Promise<ServicesListResponse> {
  return api
    .get("/services")
    .then((response) => response.data as ServicesListResponse);
}
